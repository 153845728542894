import { createGlobalStyle } from 'styled-components';
import { ThemeType, HIGHLIGHT_COLOR } from './theme';

export const FREEZE_CLASS = 'freeze';
// export const FONT_FAMILY = "'Montserrat', 'Helvetica Neue', Arial, Helvetica, sans-serif";
export const FONT_FAMILY = "'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif";
export const SCROLLER_WIDTH = 4;

const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
html,
body {
  background: ${({ theme }) => theme.color.backgroundPrimary};
  color: ${({ theme }) => theme.color.textPrimary};
  padding: 0;
  margin: 0;
  min-height: 100%;
  font-family: ${FONT_FAMILY};
  scroll-behavior: smooth;

  h1, h2 {
    font-family: 'Montserrat Alternates', ${FONT_FAMILY};
  }
}

::selection {
  background-color: ${HIGHLIGHT_COLOR};
  color: white;
}

body {
  overflow-x: hidden;
  min-width: 320px;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: 1.425;
}

li {
  margin-bottom: 8px;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
  border-radius: 4px;
}

sup {
  font-size: x-small;
}

a {
  color: ${({ theme }) => theme.color.textPrimary};
  text-decoration: none;

  &:visited {
    color: ${({ theme }) => theme.color.textPrimary};
    text-decoration: none;
  }
}

/* svg {
  fill: ${({ theme }) => theme.color.textPrimary};
} */

.${FREEZE_CLASS} {
  overflow-y: hidden;
}

/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism-tomorrow&languages=markup+css+clike+javascript+jsx */
/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

code[class*='language-'],
pre[class*='language-'] {
  color: #ccc;
  background: none;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  font-size: 12px;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

/* Code blocks */
pre[class*='language-'] {
  padding: 1em 1.5em;
  padding-top: 1.1em;
  overflow: auto;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #2F3136;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #999;
}

.token.punctuation {
  color: #ccc;
}

.token.tag,
.token.attr-name,
.token.namespace,
.token.deleted {
  color: #e2777a;
}

.token.function-name {
  color: #6196cc;
}

.token.boolean,
.token.number,
.token.function {
  color: #f08d49;
}

.token.property,
.token.class-name,
.token.constant,
.token.symbol {
  color: #f8c555;
}

.token.selector,
.token.important,
.token.atrule,
.token.keyword,
.token.builtin {
  color: #cc99cd;
}

.token.string,
.token.char,
.token.attr-value,
.token.regex,
.token.variable {
  color: #7ec699;
}

.token.operator,
.token.entity,
.token.url {
  color: #67cdcc;
}

.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.inserted {
  color: green;
}


.bx--form-item {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
  }

  .bx--date-picker {
    display: flex;
    width: 100%;
  }

  .bx--date-picker-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .bx--label {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.34;
    letter-spacing: 0.32px;
    display: inline-block;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.color.textPrimary};
    font-weight: 400;
    line-height: 1rem;
    vertical-align: baseline;
  }

  .bx--date-picker-input__wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .bx--date-picker.bx--date-picker--single .bx--date-picker__input {
    width: 100%;
  }

  .bx--date-picker__input {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.32px;
    outline: 2px solid transparent;
    outline-offset: -2px;
    position: relative;
    display: block;
    height: 2.5rem;
    padding: 10px 12px;
    color: ${({ theme }) => theme.color.textPrimary};
    background-color: ${({ theme }) => theme.color.backgroundSecondary};
    border: none;
    border: 2px solid ${({ theme }) => theme.color.textMuted};
    transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9) all;
    border-radius: 4px;
    height: 36px;

    &:focus {
      border-color: ${({ theme }) => theme.color.buttonPrimary};
    }
  }

  .bx--date-picker__icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    z-index: 1;
    transform: translateY(-50%);
    cursor: pointer;
    fill: currentColor;
  }

  .flatpickr-calendar {
    background: transparent;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    padding: 0;
    animation: none;
    direction: ltr;
    border: 0;
    border-radius: 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 315px;
    box-sizing: border-box;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background: ${({ theme }) => theme.color.backgroundPrimary};
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6,
      0 3px 13px rgb(0 0 0 / 8%);
  }

  .flatpickr-calendar.open,
  .flatpickr-calendar.inline {
    opacity: 1;
    visibility: inherit;
    overflow: visible;
    max-height: 640px;
  }

  .flatpickr-calendar.open {
    box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 18rem;
    height: 21rem;
    padding: 0.25rem 0.25rem 0.5rem 0.25rem;
    border: none;
    margin-top: -2px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
  }

  .flatpickr-calendar.animate.open {
    animation: fpFadeInDown 110ms cubic-bezier(0, 0, 0.38, 0.9);
  }

  .flatpickr-months {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;
    fill: ${({ theme }) => theme.color.textSecondary};
    transform: scale(1, 1) /*rtl: scale(-1,1)*/;
    transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    line-height: 16px;
    height: 28px;
    padding: 10px calc(3.57% - 1.5px);
    z-index: 3;
  }

  .flatpickr-month {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.16px;
    height: 2.5rem;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: ${({ theme }) => theme.color.textPrimary};
    line-height: 1;
    text-align: center;
  }

  .flatpickr-current-month {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.75rem;
    text-align: center;
  }

  .flatpickr-current-month .cur-month {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .numInputWrapper {
    position: relative;
    width: 3.75rem;
  }

  .numInputWrapper .numInput {
    display: inline-block;
    width: 100%;
    padding: 0.25rem;
    margin: 0;
    border: none;
    color: ${({ theme }) => theme.color.textPrimary};
    font-size: inherit;
    font-family: inherit;
    font-weight: 600;
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    cursor: default;
    -moz-appearance: textfield;
  }

  .numInputWrapper .arrowUp,
  .numInputWrapper .arrowDown {
    position: absolute;
    left: 2.6rem;
    width: 0.75rem;
    height: 50%;
    border: none;
    padding: 0 0.25rem 0 0.125rem;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
  }

  .numInputWrapper .arrowUp {
    top: 0.25rem;
    border-bottom: 0;
  }

  .numInputWrapper .arrowDown {
    top: 0.6875rem;
  }

  .numInputWrapper .arrowUp::after,
  .numInputWrapper .arrowDown::after {
    position: absolute;
    top: 33%;
    display: block;
    content: '';
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
  }

  .numInputWrapper .arrowUp::after {
    border-bottom: 0.25rem solid #161616;
  }

  .numInputWrapper .arrowDown::after {
    border-top: 0.25rem solid #161616;
  }

  .numInputWrapper .arrowDown::after {
    border-top: 0.25rem solid #161616;
  }

  .flatpickr-weekdaycontainer {
    display: flex;
    width: 100%;
  }

  .dayContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 15.375rem;
    padding: 0;
    outline: 0;
  }

  .flatpickr-weekday {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    cursor: default;
    color: ${({ theme }) => theme.color.textPrimary};
    flex: 1;
  }

  .flatpickr-day {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.29;
    letter-spacing: 0.16px;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.textPrimary};
    cursor: pointer;
    transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);

    &:hover {
      background-color: ${({ theme }) => theme.color.buttonPrimary};
    }
  }

  .nextMonthDay,
  .prevMonthDay {
    color: ${({ theme }) => theme.color.textSecondary};
  }

  .flatpickr-day.today {
    position: relative;
    color: ${({ theme }) => theme.color.buttonPrimary};
    font-weight: 600;
  }

  .flatpickr-day.selected {
    color: ${({ theme }) => theme.color.textPrimary};
    background-color: ${({ theme }) => theme.color.buttonPrimary};
  }
`;

export default GlobalStyle;
