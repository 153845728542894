import * as React from 'react';
import { AppProps } from 'next/app';
import ThemeProvider from 'components/context/ThemeProvider';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from 'utils/client/apolloClient';
import { ViewerProvider } from 'components/context/ViewerContext';
import 'tailwindcss/tailwind.css';
import 'styles/globals.css';

function MyApp({ Component, pageProps, err }: AppProps & { err: any }) {
  const apolloClient = useApollo(pageProps);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="monetization" content="$ilp.uphold.com/4FZDaEHX9323" />
      </Head>
      <ViewerProvider>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider>
            <Component {...pageProps} err={err} />
          </ThemeProvider>
        </ApolloProvider>
      </ViewerProvider>
    </>
  );
}

export default MyApp;
