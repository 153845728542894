import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';

// NOTE: CHANGE THESE
const DEV_CONFIG = {
  apiKey: 'AIzaSyBO3cCQHxxF6Rb1t3W6TBjvglDDl31j8CM',
  authDomain: 'maven-team-staging.firebaseapp.com',
  projectId: 'maven-team-staging',
  storageBucket: 'maven-team-staging.appspot.com',
  messagingSenderId: '352920187706',
  appId: '1:352920187706:web:f66df0ed9339dba3531603',
  measurementId: 'G-LM5H0MTDM6',
  databaseURL: 'https://maven-team-staging.firebaseio.com',
};

const PROD_CONFIG = {
  apiKey: 'AIzaSyBJb4S87yvCgBJQZLzUEICqGQHef19jWoc',
  authDomain: 'mavyn-team.firebaseapp.com',
  projectId: 'mavyn-team',
  storageBucket: 'mavyn-team.appspot.com',
  messagingSenderId: '294408116609',
  appId: '1:294408116609:web:036acc64fab2fb60d8f080',
  measurementId: 'G-LWP02XEMG9',
  databaseURL: 'https://mavyn-team.firebaseio.com',
};

export const firebaseConfig = process.env.APP_STAGE !== 'production' ? DEV_CONFIG : PROD_CONFIG;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const app = firebase.app();
const auth = firebase.auth();
const storage = firebase.storage();
const database = firebase.database();

if (process.env.APP_STAGE !== 'production') {
  console.log(app.name ? 'Firebase Mode Activated!' : 'Firebase not working :(');
}

export { auth, storage, database };
