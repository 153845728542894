import firebase from 'firebase/app';
import { auth, database } from 'services/client/firebase';
import { LOGIN_PAGE } from 'utils/constants/pages';
import { HASURA_CLAIMS_KEY } from 'utils/constants/auth';

export type FirebaseUserOrNull = firebase.User | null;
type TokenOrNull = string | null;
interface UpdateResponse {
  user: FirebaseUserOrNull;
  token: TokenOrNull;
  claims: null;
}
type FirebaseCallback = ({ user, token }: UpdateResponse) => void;

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const FIREBASE_REFRESH_POLL_TIME = 20 * MINUTE;
const MAX_LOGIN_TIME = 7 * DAY;

let token: TokenOrNull = null;

export const updateViewerToken = (
  user: FirebaseUserOrNull,
  shouldForceRefresh?: boolean,
): Promise<UpdateResponse> => {
  return new Promise(async (resolve) => {
    if (user) {
      const firebaseToken = await user.getIdToken(shouldForceRefresh);
      const idTokenResult = await user.getIdTokenResult();
      const hasuraClaim = idTokenResult.claims[HASURA_CLAIMS_KEY];
      token = firebaseToken;

      if (
        idTokenResult.claims.auth_time &&
        idTokenResult.claims.auth_time * 1000 + MAX_LOGIN_TIME < Date.now()
      ) {
        await auth.signOut();
        window.location.href = LOGIN_PAGE;
      }

      if (hasuraClaim) {
        return resolve({ user, token, claims: hasuraClaim });
      } else {
        // Check if refresh is required.
        const metadataRef = database.ref('metadata/' + user.uid + '/refreshTime');

        metadataRef.on('value', async (data) => {
          if (!data.exists()) return;
          // Force refresh to pick up the latest custom claims changes.
          token = await user.getIdToken(true);
          const idTokenResult = await user.getIdTokenResult();
          const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];

          return resolve({ user, token, claims: hasuraClaim });
        });
      }
    } else {
      token = null;
      return resolve({ user, token, claims: null });
    }
  });
};

const pollForToken = (callback: FirebaseCallback) => {
  setInterval(() => {
    const currentUser = auth.currentUser;
    updateViewerToken(currentUser, true).then((res: UpdateResponse) => callback(res));
  }, FIREBASE_REFRESH_POLL_TIME);
};

// NOTE: This should probably be a React hook and manage sub/unsub of these methods.
// However, with the client-side routing, it seems to only mount once and work correctly,
// but should be monitored for potential leaks.
export const watchViewerTokenChanges = (callback: FirebaseCallback) => {
  auth.onAuthStateChanged(async (user) => {
    updateViewerToken(user, true).then((res: UpdateResponse) => callback(res));
  });
  // auth.onIdTokenChanged(async (user) => {
  //   updateViewerToken(user).then((res: UpdateResponse) => callback(res));
  // });

  if (typeof window !== 'undefined') {
    pollForToken(callback);
    // NOTE: event listener for tab focus if the poll stops working on tab change
  }
};

export const getViewerToken = () => {
  if (token) {
    return token;
  }

  return null;
};
