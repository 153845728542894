import * as React from 'react';
import { watchViewerTokenChanges } from 'services/client/token';
import { AuthStatus } from 'utils/constants/auth';
import { Viewer } from 'utils/constants/user';

const DEFAULT_USER: Viewer = {
  status: AuthStatus.Loading,
  viewer: null,
  config: null,
  claims: null,
};

export const ViewerContext = React.createContext(DEFAULT_USER);

export const ViewerProvider: React.FC = ({ children }) => {
  const [viewer, setViewer] = React.useState(DEFAULT_USER);

  React.useEffect(() => {
    watchViewerTokenChanges(({ user, claims }) => {
      if (user) {
        setViewer({
          status: AuthStatus.User,
          viewer: user,
          config: null,
          claims,
        });
      } else {
        setViewer({
          status: AuthStatus.Anonymous,
          viewer: null,
          config: null,
          claims: null,
        });
      }
    });
  }, []);

  return <ViewerContext.Provider value={viewer}>{children}</ViewerContext.Provider>;
};
