import company from 'styles/company';

export const NAV_HEIGHT = 56;
export const TITLE_HEIGHT = 40;
export const FOCUS_WIDTH = 2;
export const HIGHLIGHT_COLOR = '#3583E8';
export const COLOR_BRAND = '#3583E8';

const zIndex = {
  z1: 100,
  z2: 200,
  z3: 300,
  z4: 400,
  z5: 500,
  z6: 600,
  z7: 700,
  z8: 800,
  z9: 900,
  z10: 1000,
  nav: 900,
  modal: 1000,
};

const spacing = {
  gutter: 20,
  navHeight: NAV_HEIGHT,
  titleHeight: TITLE_HEIGHT,
  verticalPagePad: 32,
};

export enum BasePalette {
  GraphGreen = '#6BE594',
  GraphRed = '#f04662',
  GraphPurple = '#B64EC7',
  GraphRedDark = '#CB514C',
  GraphYellow = '#ffc045',
  YellowBright = '#F6F49E',
  GraphOrange = '#EF8A52',
  // CallBackground = '#29c26f',
  CallBackground = '#17be64',
  PutBackground = '#f04662',
  CallText = '#05EA8C',
  PutText = '#ea7b7b',
  LabelText = '#a0aac4',
  Brand = '#303fe1',
  Red = '#FF695E',
  Orange = '#FF851B',
  Yellow = '#FFE21F',
  Green = '#2ECC40',
  Teal = '#19A69E',
  SkyBlue = '#54C8FF',
  Blue = '#2185D0',
  Violet = '#725eec',
  Purple = '#DC73FF',
  Pink = '#FF8EDF',
  Brown = '#D67C1C',
  Black = '#000000',
  White = '#FFFFFF',
  Gray = '#BFBEBE',
  TopPosition = '#1bbeff',
  // Button = '#267dea',
  // ButtonHover = '#1465cb',
  // NOTE: matching the webflow theme
  Button = '#303fe1',
  ButtonHover = '#2432cf', // think of a good hover color
  AccentBlue = '#3C8DF7',
}

// NOTE: Inspired by discord app
// export const DarkPalette = {
//   Black0: '#000000',
//   Black1: '#040405',
//   Black2: '#121212',
//   Black3: '#202225',
//   Black4: '#36393F',
//   Black5: '#4F545C',
//   Black6: '#72767D',
//   Black7: '#B9BBBE',
//   Black8: '#DCDDDE',
//   Black9: '#F6F6F7',
//   Black10: '#FFFFFF',
// };
// NOTE: Matching the webflow theme
export const DarkPalette = {
  Black0: '#000000',
  Black1: '#0D0F19',
  Black2: '#151720',
  Black3: '#1c1e27',
  Black4: '#20222b',
  Black5: '#4F545C',
  Black6: '#72767D',
  Black7: '#B9BBBE',
  Black8: '#DCDDDE',
  Black9: '#F6F6F7',
  Black10: '#FFFFFF',
};

export interface ColorType {
  labelText: string;
  backgroundPrimary: string;
  backgroundSecondary: string;
  backgroundDistinct: string;
  navBackground: string;
  dropdownBackground: string;
  paper: string;
  tileBorder: string;
  textPrimary: string;
  textSecondary: string;
  textLight: string;
  textFaded: string;
  textMuted: string;
  inverted: string;
  invertedSecondary: string;
  brand: string;
  cta: string;
  border: string;
  overlay: string;
  focus: string;
  buttonPrimary: string;
  buttonHover: string;
  buttonOutline: string;
  error: string;
  danger: string;
  alert: string;
  alertHover: string;
  highlight: string;
  basePalette: typeof BasePalette;
  blackPalette: typeof DarkPalette;
}

export interface ThemeType {
  color: ColorType;
  focus: string;
  spacing: typeof spacing;
  zIndex: typeof zIndex;
  company: typeof company;
  shadow: {
    stroke: string;
    low: string;
    medium: string;
    high: string;
  };
}

export const themeLight: ThemeType = {
  color: {
    labelText: BasePalette.LabelText,
    backgroundPrimary: '#FFFFFF',
    backgroundSecondary: '#E9EBEE',
    backgroundDistinct: '#393C3E',
    paper: '#FFFFFF',
    navBackground: '#FFFFFF',
    dropdownBackground: '#FFFFFF',
    tileBorder: '#272727',
    textPrimary: 'rgba(0, 0, 0, 0.65)',
    textSecondary: '#555555',
    textLight: '#9f9f9f',
    textMuted: '#9f9f9f',
    textFaded: '#5b5d63',
    inverted: '#000000',
    invertedSecondary: '#000000',
    brand: BasePalette.Brand,
    cta: BasePalette.Green,
    border: '#C1C0C0',
    overlay: '#FFFFFF',
    focus: BasePalette.White,
    buttonPrimary: BasePalette.Button,
    buttonHover: BasePalette.ButtonHover,
    buttonOutline: BasePalette.Brand,
    error: '#ff194a',
    danger: '#ff3366',
    alert: '#f14c4e',
    alertHover: '#ed1d1f',
    highlight: HIGHLIGHT_COLOR,
    basePalette: BasePalette,
    blackPalette: DarkPalette,
  },
  focus: `${FOCUS_WIDTH}px solid ${BasePalette.White}`,
  spacing,
  zIndex,
  company,
  shadow: {
    stroke: '0 0 0 1px rgba(6,6,7,0.08)',
    low: '0 1px 0 rgba(6,6,7,0.1),0 1.5px 0 rgba(6,6,7,0.025),0 2px 0 rgba(6,6,7,0.025)',
    medium: '0 4px 4px rgba(0,0,0,0.08)',
    high: '0 8px 16px rgba(0,0,0,0.16)',
  },
};

export const themeDark: ThemeType = {
  color: {
    labelText: BasePalette.LabelText,
    backgroundPrimary: DarkPalette.Black1,
    backgroundSecondary: DarkPalette.Black2,
    backgroundDistinct: DarkPalette.Black3,
    paper: DarkPalette.Black4,
    navBackground: DarkPalette.Black1,
    dropdownBackground: DarkPalette.Black1,
    tileBorder: '#272727',
    textPrimary: '#FFFFFF',
    textSecondary: '#B9BBBE',
    textLight: '#9f9f9f',
    textFaded: '#5b5d63',
    textMuted: DarkPalette.Black5,
    inverted: DarkPalette.Black10,
    invertedSecondary: DarkPalette.Black9,
    brand: BasePalette.Brand,
    cta: '#30a84f',
    // border: DarkPalette.Black4,
    border: 'rgba(255, 255, 255, 0.1)',
    overlay: '#000000',
    focus: BasePalette.White,
    buttonPrimary: BasePalette.Button,
    buttonHover: BasePalette.ButtonHover,
    buttonOutline: BasePalette.White,
    error: '#ff194a',
    danger: '#ff3366',
    alert: '#f14c4e',
    alertHover: '#ed1d1f',
    highlight: HIGHLIGHT_COLOR,
    basePalette: BasePalette,
    blackPalette: DarkPalette,
  },
  focus: `${FOCUS_WIDTH}px solid ${BasePalette.White}`,
  spacing,
  zIndex,
  company,
  shadow: {
    stroke: '0 0 0 1px rgba(6,6,7,0.08)',
    low: '0 1px 0 rgba(6,6,7,0.1),0 1.5px 0 rgba(6,6,7,0.1),0 2px 0 rgba(6,6,7,0.1)',
    medium: '0 4px 4px rgba(0,0,0,0.18)',
    high: '0 20px 40px rgba(0,0,0,1)',
  },
};

export enum ThemeNames {
  Light = 'LIGHT',
  Dark = 'DARK',
}

export const themeMap = {
  [ThemeNames.Light]: themeLight,
  [ThemeNames.Dark]: themeDark,
};
